.signBox[data-v-3c6ae19b] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.signImg[data-v-3c6ae19b] {
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 100px;
  width: calc(100% - 100px);
  margin-right: 10px;
  text-align: center;
}
.signImg img[data-v-3c6ae19b] {
  max-height: 100px;
  max-width: 100%;
}
.qrcode-img[data-v-3c6ae19b] {
  width: 260px;
  margin: 0 auto;
}