[data-v-4c8f0a84].selectPeopleByCurrentPrjoect .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}
[data-v-4c8f0a84] .el-tree {
  height: 60vh !important;
  max-height: 60vh !important;
  overflow: scroll;
}
[data-v-4c8f0a84] .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}